.parent-flex-container {
    width: 90%;
    max-width: fit-content;
    min-width: auto;
    padding: 10px 0px;
    box-sizing: border-box;
}
.export-buttons {
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

}

.export-buttons button {
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
}
.export-buttons span:first-child{
    color: var(--ProjectColor);
}
.export-buttons span:last-child{
    color: rgb(68, 68, 68);
}
.flex-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    max-height: 400px;
    z-index: 0;
}


.flex-container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

.flex-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.flex-container::-webkit-scrollbar-thumb {
    background: var(--ProjectColor);
    border-radius: 6px;
}

.flex-container::-webkit-scrollbar-thumb:hover {
    background: var(--opacityColor);
}
/* .flex-container::-webkit-scrollbar-corner {
    background-color: var(--ProjectColor);
} */

.flex-row {
    display: flex;
    width: fit-content;
    background-color: inherit;
}
.flex-row:hover{
    background-color: var(--ProjectColorhover) !important;
}

.flex-row-cell {
    width: fit-content;
}

.flex-row-cell>.flex-row {
    height: 45px;
}
.column-header {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ddd;
    background-color: var(--ProjectColor);
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    padding: 5px 10px;
    box-sizing: border-box;

}
.column-header-cell{
    min-height: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.column-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #ddd;
    box-sizing: border-box;
}

.column-header input,
.column-header select {
    width: 120px;
    background-color: #ffff;
    border: 0px;
    outline: 0px;
    height: 30px;
    padding: 0px 5px;
    border-radius: 5px;
    font-size: 12px;
}

.column-cell {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    font-weight: lighter;
    overflow: hidden;
    white-space: nowrap;
    padding: 0px 5px;
    font-size: 15px;
    box-sizing: border-box;
}
.column-cell:hover{
    background-color: var(--ProjectColorhover);
}

.header {
    position: sticky;
    top: 0;
    z-index: 2;
    height: auto;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 1;
    
}
.column-group-cell {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

.column-group-cell>.column-cell {
    border-bottom: 0px;
}

.column-header:last-child,
.column-cell:last-child {
    border-right: 0 !important;
}





.column-resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    height: 100%;
    cursor: col-resize;
    background-color: transparent;
}

.column-resizer:hover {
    background-color: #ddd;
}

.footer-flex-container{
    display: flex;
    justify-content: center;
    align-items: center;
}